<template>
    <columns>
        <column>
            <columns>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="barcode" class="fa-2x has-text-grey" />
                        <p>
                            Total # of Equipment
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <p class="is-size-3">{{ overview.total_equipment | abbreviateCount }}</p>
                            <difference-tag 
                                :last="overview.total_equipment_last_month"
                                :current="overview.total_equipment"
                            />
                        </div>
                    </div>
                </column>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="helmet-safety" class="fa-2x has-text-grey" />
                        <p>
                            Open Work Orders
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <p class="is-size-3">{{ overview.open_work_orders | abbreviateCount }}</p>
                            <difference-tag 
                                :last="overview.work_orders_last_month" 
                                :current="overview.work_orders_this_month"
                            />
                        </div>
                    </div>
                </column>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="qrcode" class="fa-2x has-text-grey" />
                        <p>
                            Faulty Equipment
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <p class="is-size-3">{{ overview.faulty_equipment | abbreviateCount }}</p>
                            <!-- <span class="tag is-success">+ 3%</span> -->
                        </div>
                    </div>
                </column>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="bolt" class="fa-2x has-text-grey" />
                        <p>
                            Work Orders Created Today
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <p class="is-size-3">{{ overview.work_orders_created_today | abbreviateCount }}</p>
                        </div>
                    </div>
                </column>
                <column>
                    <div class="box is-fullheight is-round p-5">
                        <icon icon="bolt" class="fa-2x has-text-grey" />
                        <p>
                            Overdue Work Orders
                        </p>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <p class="is-size-3">{{ overview.overdue_work_orders | abbreviateCount }}</p>
                            <difference-tag 
                                postive-is-bad
                                :last="overview.overdue_work_orders_last_month" 
                                :current="overview.overdue_work_orders" 
                            />
                        </div>
                    </div>
                </column>
            </columns>
            <div class="box" v-if="!$root.isPassiveUser()">
                <work-carried-out-chart :summary="overview.checklist_graph" />
            </div>
            <columns v-if="!$root.isPassiveUser()">
                <column>
                    <top-performing-teams :top-teams="overview.top_teams"/>
                </column>
            </columns>
        </column>
        <column class="is-3">
            <p class="is-size-3">Overall Health</p>
            <small class="has-text-grey">Operational Equipment vs Faulty Equipment</small>
            <faulty-equipment-donut :faulty="overview.equipment_summary.faulty"
                :operational="overview.equipment_summary.operational" />
            <p class="is-size-5 has-text-weight-bold">Recent Activity</p>
            <columns v-for="checklist in filteredChecklists" :key="checklist.id">
                <column>
                    <p>
                        <router-link :to="{
                            name: 'checklist-manager',
                            params: {
                                checklist: checklist.uuid
                            }
                        }">
                            {{ checklist.checklist.name }}
                        </router-link>
                        <br>
                        <small>{{ checklist.inspectable.name }}</small>
                        <br>
                        <small class="has-text-grey">At {{ checklist.work_order.location.name }}, Updated {{
                                checklist.updated_at | asCalendar
                        }}</small>
                    </p>
                </column>
                <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                    <circular-progress-bar tooltip="Current progress for this checklist" :total="checklist.answers.length"
                        :total-complete="checklist.answers.filter(answer => answer.complete).length" />
                </column>
            </columns>
        </column>
    </columns>
</template>
<script>
import WorkCarriedOutChart from './partials/WorkCarriedOutChart.vue'
import FaultyEquipmentDonut from './partials/FaultyEquipmentDonut.vue'
import TopPerformingTeams from './partials/TopTeamsChart.vue'
// import TopStaff from './partials/TopStaff.vue'

export default {

    props: {
        overview: {
            type: Object,
            default: () => ({
                total_equipment: 0,
                total_equipment_last_month: 0,
                total_equipment_this_month: 0,
                faulty_equipment: 0,
                faulty_equipment_last_month: 0,
                overdue_checklists: 0,
                overdue_checklists_last_month: 0,
                overdue_checklists_this_month: 0,
                open_work_orders: 0,
                overdue_work_orders_last_month: 0,
                overdue_work_orders: 0,
                work_orders_last_month: 0,
                work_orders_this_month: 0,
                recent_checklists: [],
                checklist_graph: [],
                top_teams: [],
                equipment_summary: {
                    operational: 0,
                    faulty: 0,
                }
            })
        }
    },

    components: {
        WorkCarriedOutChart,
        FaultyEquipmentDonut,
        TopPerformingTeams,
        // TopStaff,
    },

    computed: {
        filteredChecklists() {
            return this.overview.recent_checklists.filter(checklist => checklist.checklist)
        }
    }

}
</script>